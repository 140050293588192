













































































































import { Component } from 'vue-property-decorator';

import EvaluationDataTable from '@/components/evaluation/EvaluationDataTable.vue';
import MixinMain from '@/mixing/MixinMain.vue';
import AppContainerBase from '@/components/util/AppContainerBase.vue';
import { IItemSelect } from '@/model/util/IItemSelect';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';
import { IFilterEvaluation } from '@/model/evaluation/IFilterEvaluation';
import { Icon } from '@/model/util/Icon';
@Component({
	name: 'MainEvaluation.vue',
	components: {
		EvaluationDataTable,
		AppContainerBase,
	},
})
export default class MainEvaluation extends MixinMain {
	public isLoading = false;
	public selectCustomer: Array<IItemSelect> = [];
	public selectCamping: Array<IItemSelect> = [];
	public selectAgent: Array<IItemSelect> = [];
	public selectTypeCall: Array<IItemSelect> = [];
	public selectSub: Array<IItemSelect> = [];
	public selectAudio: Array<IItemSelect> = [];

	public selected: Array<string> = ['', '', '', '', '', ''];
	public disables: Array<boolean> = [
		true,
		true,
		true,
		true,
		true,
	];

	mounted() {
		this.isLoading = true;
		internet
			.newRequest()
			.get('separacion/cliente')
			.then((response) => {
				this.selectCustomer.push({
					text: this.$t("DataTable.seleccion") as string,
					value: '',
				});
				for (let item of response.data
					.response as Array<
					Record<string, string>
				>) {
					this.selectCustomer.push({
						text: item._id,
						value: item._id,
					});
				}
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}

	public loadCampaing() {
		if (this.selected[0] !== '') {
			this.isLoading = true;
			internet
				.newRequest()
				.get(
					`separacion/campana/${this.selected[0]}`
				)
				.then((response) => {
					this.selectCamping.push({
						text: this.$t("DataTable.seleccion") as string,
						value: '',
					});
					for (let item of response.data.response
						.Campania as Array<
						Record<string, string>
					>) {
						this.selectCamping.push({
							text: item,
							value: item,
						});
					}
					this.disables[0] = false;
				})
				.catch(console.log)
				.finally(() => {
					this.isLoading = false;
				});
		}
	}
	public loadAgent() {
		if (this.selected[1] !== '') {
			this.isLoading = true;
			internet
				.newRequest()
				.get(`xentric/agente/${this.selected[1]}`)
				.then((response) => {
					this.selectAgent.push({
						text: this.$t("DataTable.seleccion") as string,
						value: '',
					});
					for (let item of response.data
						.response as Array<
						Record<string, string>
					>) {
						this.selectAgent.push({
							text: item._id,
							value: item._id,
						});
					}
					this.disables[1] = false;
				})
				.catch(console.log)
				.finally(() => {
					this.isLoading = false;
				});
		}
	}
	public loadType() {
		if (this.selected[2] !== '') {
			this.isLoading = true;
			internet
				.newRequest()
				.get(
					`xentric/tipo_llamada/${this.selected[2]}`
				)
				.then((response) => {
					this.selectTypeCall.push({
						text: this.$t("DataTable.seleccion") as string,
						value: '',
					});
					for (let item of response.data as Array<
						Record<string, string>
					>) {
						this.selectTypeCall.push({
							text: Util.replaceTextWith(
								item.tipo_llamada,
								'_',
								' '
							),
							value: item.tipo_llamada,
						});
					}
					this.disables[2] = false;
				})
				.catch(console.log)
				.finally(() => {
					this.isLoading = false;
				});
		}
	}
	public loadSub() {
		if (this.selected[3] !== '') {
			this.isLoading = true;
			internet
				.newRequest()
				.get(
					`xentric/agente/audiosScript/${this.selected[2]}/${this.selected[3]}`
				)
				.then((response) => {
					this.selectSub.push({
						text: this.$t("DataTable.seleccion") as string,
						value: '',
					});
					for (let item of response.data
						.response as Array<
						Record<string, string>
					>) {
						this.selectSub.push({
							text: item._id,
							value: item._id,
						});
					}
					this.disables[3] = false;
				})
				.catch(console.log)
				.finally(() => {
					this.isLoading = false;
				});
		}
	}
	public loadAudio() {
		if (this.selected[4] !== '') {
			this.isLoading = true;
			internet
				.newRequest()
				.get(
					`xentric/agente/audiosScript/${this.selected[2]}/${this.selected[3]}/${this.selected[4]}`
				)
				.then((response) => {
					this.selectAudio.push({
						text: this.$t("DataTable.seleccion") as string,
						value: '',
					});
					for (let item of response.data as Array<
						Record<string, string>
					>) {
						this.selectAudio.push({
							text: item.audio,
							value: {
								audio:item.audio,
								AudioRuta: item.AudioRuta
							},
						});
					}
					this.disables[4] = false;
				})
				.catch(console.log)
				.finally(() => {
					this.isLoading = false;
				});
		}
	}
	public sendTo() {
		let error = false;
		for (let value of this.selected) {
			if (value === '') {
				error = true;
				break;
			}
		}
		if (error) {
			Util.showMessage(
				this.$t("DataTable.warning") as string,
				Icon.INFO,
				this.$t("DataTable.cuidado") as string
			);
			return;
		}
		const audioSelect:any = this.selected[5];

		const filter: IFilterEvaluation = {
			customer: this.selected[0],
			campaing: this.selected[1],
			agent: this.selected[2],
			callType: this.selected[3],
			subCategory: this.selected[4],
			audio: audioSelect.audio,
			ruta: audioSelect.ruta
		};
		this.$router.push({
			name: 'ResultEvaluation',
			query: {
				filter: JSON.stringify(filter),
			},
		});
	}
}
